// NotFound.js
import React from "react";

const NotFound = () => {
  return (
    <div className="bg-gray-900 text-white font-mono justify-center items-center ">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFound;
