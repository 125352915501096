// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAZhPEOF38J2neJdfUcC4dUnsk8axJFy4k",
  authDomain: "magician-web-app.firebaseapp.com",
  projectId: "magician-web-app",
  storageBucket: "magician-web-app.appspot.com",
  messagingSenderId: "541934037816",
  appId: "1:541934037816:web:474d0db3362ddb35ac6fcd",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);

  firebase.firestore().settings({
    // experimentalForceLongPolling: true,
    merge: true,
  });
}

export { firebase };
