import React, { useEffect } from "react";
import Webcam from "react-webcam";
import FaceDetection from "@mediapipe/face_detection";
import { Camera } from "@mediapipe/camera_utils";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

import { useFaceDetection } from "./face-detect-hook/index.js";
import { firebase } from "./utils/config.js";

import backgroundHalfBottomImage from "./img/background-half-bottom.jpg";
import buttonImage from "./img/button.png";
import buttonCaptureImage from "./img/button-capture.png";
import logoImage from "./img/logo.png";
import cameraSound from "./utils/camera-sound.mp3";

//Test browser support
const SUPPORTS_MEDIA_DEVICES = "mediaDevices" in navigator;

// Get the screen dimensions
const width = window.innerWidth;
const height = window.innerWidth;

const WebCamViewer = () => {
  const [displayImage, setDisplayImage] = useState(false);
  // const [constants, setConstants] = useState({});

  const { webcamRef, detected } = useFaceDetection({
    faceDetectionOptions: {
      model: "short",
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
    }),

    camera: ({ mediaSrc, onFrame }) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
        facingMode: "environment",
      }),
  });

  const [url1, setUrl1] = useState("");
  const [preselectedImage, setPreselectedImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const constantsRef = firebase.firestore().collection("constants");

        // Fetch url1
        const url1Doc = await constantsRef.doc("url1").get();
        const url1Data = url1Doc.data();
        if (url1Data) {
          setUrl1(url1Data.link);
        }

        // Fetch preselectedImage
        const preselectedImageDoc = await constantsRef
          .doc("preSelectedImage")
          .get();
        const preselectedImageData = preselectedImageDoc.data();
        if (preselectedImageData) {
          setPreselectedImage(preselectedImageData.link);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const displayPreImage = async () => {
    try {
      // Update the local state
      await playCaptureSoundAndFlash();
      setDisplayImage(true);
    } catch (error) {
      console.error("Error displaying image:", error);
    }
  };

  const [flash, setFlash] = useState(false);

  const playCaptureSoundAndFlash = async () => {
    // Simulate a camera capture sound (you can use an audio file)
    const audio = new Audio(cameraSound);
    audio.play();

    // Simulate a flash effect by toggling a CSS class on a screen element
    setFlash(true);

    if (SUPPORTS_MEDIA_DEVICES) {
      //Get the environment camera (usually the second one)
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (cameras.length === 0) {
          throw "No camera found on this device.";
        }
        const camera = cameras[cameras.length - 1];

        // Create stream and get video track
        navigator.mediaDevices
          .getUserMedia({
            video: {
              deviceId: camera.deviceId,
              facingMode: ["user", "environment"],
              height: { ideal: 50 },
              width: { ideal: 50 },
            },
          })
          .then(async (stream) => {
            const track = stream.getVideoTracks()[0];

            //let there be light!
            track.applyConstraints({
              advanced: [{ torch: true }],
            });

            await setTimeout(() => {
              track.applyConstraints({
                advanced: [{ torch: false }],
              });
              setFlash(false);
            }, 500); // Adjust the delay as needed
          });
      });
    }
  };

  return (
    <div className="flex flex-1 h-screen flex-col bg-gray-900 text-black font-semibold items-start">
      <div
        className={`z-40 flex flex-1 w-screen h-screen absolute bg-white top-0 left-0 flash ${
          flash ? "opacity-80" : "opacity-0"
        }`}
      ></div>

      <div className="flex flex-1 flex-col w-screen items-center">
        {/* {boundingBox.map((box, index) => (
          <div
            key={`${index + 1}`}
            style={{
              border: "4px solid red",
              position: "absolute",
              top: `${box.yCenter * 100}%`,
              left: `${box.xCenter * 100}%`,
              width: `${box.width * 100}%`,
              height: `${box.height * 100}%`,
              zIndex: 1,
            }}
          />
        ))} */}

        {loading ? (
          <div>Loading Camera...</div>
        ) : displayImage ? (
          <img
            src={preselectedImage}
            alt="App Logo"
            className="aspect-square invert-75"
            style={{ width, height }}
          />
        ) : (
          <Webcam
            ref={webcamRef}
            style={{
              height,
              width,
            }}
            height={height}
            width={width}
            className={detected ? "invert-75" : "invert-75"}
            videoConstraints={{
              facingMode: "environment",
            }}
          />
        )}
      </div>

      <div
        className="flex flex-1 flex-col w-screen items-center"
        style={{
          backgroundImage: `url(${backgroundHalfBottomImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          fillOpacity: 100,
          width,
          height,
        }}
      >
        {displayImage ? (
          <Link
            to={url1}
            className={`text-center text-base z-50 w-44 h-20 text-black font-bold m-auto aspect-square -hue-rotate-180 saturate-150 
            ${flash ? "z-30" : "z-50"}
            }
            ${flash ? "opacity-0" : "opacity-100"}`}
            style={{
              backgroundImage: `url(${buttonImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              fillOpacity: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Close</p>
          </Link>
        ) : (
          <button
            onClick={displayPreImage}
            className={` ${flash ? "z-30" : "z-50"} ${
              flash ? "opacity-0" : "opacity-100"
            } text-center text-lg text-gray-600 font-bold m-auto w-32 aspect-square hover:opacity-75 hue-rotate-1800 saturate-50`}
            style={{
              backgroundImage: `url(${buttonCaptureImage})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              fillOpacity: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Take</p>
            <p className="-mt-3">Picture</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default WebCamViewer;
