import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { firebase } from "./utils/config"; // Import Firebase configuration

import WebCamViewer from "./WebCamViewer";
import HomePage from "./HomePage";
import SearchEngine from "./SearchEngine";
import WebcamCapture from "./WebcamCapture";
import AdminPanel from "./AdminPanel";
import ViewSearchList from "./ViewSearchList";
import NotFound from "./NotFound";

function App() {
  const [appStatus, setAppStatus] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    // Fetch data from Firebase Firestore
    const fetchData = async () => {
      try {
        const constantsRef = firebase.firestore().collection("constants");
        const appStatus = await constantsRef.doc("appStatus").get();
        const appStatusData = appStatus.data();

        const url2 = await constantsRef.doc("url2").get();
        const url2Data = url2.data().link;

        setAppStatus(appStatusData);
        setRedirectUrl(url2Data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, []);

  if (appStatus === null) {
    // Loading state while fetching data
    return (
      <div className="bg-gray-900 text-white font-semibold h-screen w-screen flex flex-1 justify-center items-center">
        Loading...
      </div>
    );
  }

  // Check the value of appStatus.value and redirect accordingly
  const shouldRedirect = appStatus.value === false;

  return (
    <div className="app">
      <Router>
        {shouldRedirect ? (
          <div className="bg-gray-900 text-white font-semibold h-screen w-screen flex flex-1 justify-center items-center">
            Redirecting to... {(window.location.href = redirectUrl)}
          </div>
        ) : (
          <Routes>
            {/* 
            <Route path="/" element={<HomePage />} />
            <Route path="/webcamviewer" element={<WebCamViewer />} />
           */}

            <Route path="/" element={<WebCamViewer />} />
            <Route path="/@google.com" element={<SearchEngine />} />

            <Route path="*" Component={<NotFound />} />

            {/* <Route
              path="/-preselectimage-dWE1C55hAF"
              element={<WebcamCapture />}
            />
            <Route path="/-adminpanel-dWE1C55hAF" element={<AdminPanel />} />
            <Route
              path="/-searchlist-dWE1C55hAF"
              element={<ViewSearchList />}
            /> */}
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
