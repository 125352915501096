import React, { useState } from "react";

import { firebase } from "./utils/config";

const SearchEngine = () => {
  const [query, setQuery] = useState("");

  const handleSearch = async () => {
    if (query.trim() === "") return;

    // Add the query to Firebase Firestore
    try {
      const docName = new Date().getTime().toString();
      const timeStamp = firebase.firestore.FieldValue.serverTimestamp();

      // Save the download URL to Firestore
      const db = firebase.firestore();
      const searchRef = db.collection("search");

      // Update the preSelectedImage field
      const docRef = await searchRef.doc(docName).set({ query, timeStamp });

      console.log("Search query added with ID: ", docRef.id);
    } catch (error) {
      console.error("Error adding search query: ", error);
    }

    // Open a new tab with Google search results
    window.open(`https://www.google.com/search?q=${query}`);
  };

  return (
    <>
      <div className="h-auto w-screen mt-4 -mb-28 flex flex-row items-center justify-end space-x-5">
        <div className="space-x-3.5 mr-3 text-sm">
          <a aria-label="Gmail (opens a new tab)" href="#" target="_top">
            Gmail
          </a>

          <a
            aria-label="Search for Images (opens a new tab)"
            href="#"
            target="_top"
          >
            Images
          </a>
        </div>

        <div className="opacity-70">
          <a aria-label="Google apps" href="#">
            <svg
              focusable="false"
              viewBox="0 0 24 24"
              className="w-7 aspect-square"
            >
              <path d="M6,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM6,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM12,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM16,6c0,1.1 0.9,2 2,2s2,-0.9 2,-2 -0.9,-2 -2,-2 -2,0.9 -2,2zM12,8c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,14c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2zM18,20c1.1,0 2,-0.9 2,-2s-0.9,-2 -2,-2 -2,0.9 -2,2 0.9,2 2,2z"></path>
            </svg>
          </a>
        </div>

        <a
          href="#"
          className="py-1.5 px-6 text-center align-middle rounded-e rounded-s text-white font-normal mr-4 "
          style={{ backgroundColor: "#1a73e8", marginRight: 14 }}
        >
          Sign in
        </a>

        {/* <div className="h-fit mr-3">
          <a href="#">
            <img
              className="rounded-full overflow-hidden w-10 h-10 mr-4"
              src="https://lh3.googleusercontent.com/ogw/AKPQZvwvGzWmouDrUfQLNA3oLix9nq2iaiUGafYnpnuV=s32-c-mo"
              alt=""
            />
          </a>
        </div> */}
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen bg-white">
        {/* Google Logo */}
        <img
          src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
          alt="Google Logo"
          className="w-40 h-auto mb-8"
        />

        {/* Search Bar */}
        <div className="flex flex-row border w-11/12 border-gray-300 rounded-full hover:shadow-md overflow-hidden focus:outline-none bg-white justify-center align-middle">
          <svg
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            className="w-6 h-6 my-auto ml-3.5 opacity-70"
          >
            <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
          </svg>

          <input
            type="text"
            className="w-full px-4 py-2 focus:outline-none"
            // placeholder="Search Google"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        {/* Search Buttons */}
        <div className="mt-4 flex space-x-4">
          <button
            onClick={handleSearch}
            className="border border-white bg-gray-100 hover:border-gray-300 rounded-md text-black text-sm px-4 py-2 hover:shadow-sm"
          >
            Google Search
          </button>
          {/* <button className=" border border-gray-700 rounded-md text-black font-medium px-4 py-2 hover:bg-gray-800">
          I'm Feeling Lucky
        </button> */}
        </div>
      </div>
    </>
  );
};

export default SearchEngine;
